import { BACK_BUTTON_CLASS_NAME, CLOSE_BUTTON_CLASS_NAME, IFRAME_CLASS_NAME } from './constants';
import { handleOnBackButtonClick } from './utils/handleOnBackButtonClick';
import { handleOnClickCloseButton } from './utils/handleOnClickCloseButton';
import { handleOnClickIframe } from './utils/handleOnClickIframe';
import { onDocumentReady } from './utils/onDocumentReady';
import './config';

const main = (): void => {
  document.domain = 'king.com';
  document.querySelector('iframe')?.classList.add(IFRAME_CLASS_NAME);

  window.addEventListener('beforeunload', handleOnClickCloseButton);

  window.history.replaceState({ firstPage: true }, '', window.location.href);

  const closeButton = document.querySelector(`.${CLOSE_BUTTON_CLASS_NAME}`) as HTMLButtonElement;
  const backButton = document.querySelector(`.${BACK_BUTTON_CLASS_NAME}`) as HTMLButtonElement;
  const iframe = document.querySelector(`.${IFRAME_CLASS_NAME}`) as HTMLIFrameElement;
  iframe.attributes.removeNamedItem('scrolling');
  closeButton?.addEventListener('click', handleOnClickCloseButton);
  backButton?.addEventListener('click', handleOnBackButtonClick);
  iframe?.addEventListener('load', () =>
    iframe.contentWindow?.document.addEventListener('click', (event) => handleOnClickIframe(event, iframe))
  );

  function showBackButton(): void {
    const isButtonShowing = !backButton.classList.contains('hide');
    if (!window.history.state?.firstPage && !isButtonShowing) {
      return backButton.classList.remove('hide');
    }

    if (window.history.state?.firstPage && isButtonShowing) {
      return backButton.classList.add('hide');
    }
  }

  setInterval(showBackButton, 1000);
};

onDocumentReady(main);
