import { env } from '../config';

export const handleOnClickIframe = (event: MouseEvent, iframe: HTMLIFrameElement): void => {
  const target = event.target as Element;
  if (!target?.tagName) {
    // Make sure it is an Element by having tagName, if not, return
    return;
  }

  const anchor = target.closest('a');
  if (anchor === null) {
    return;
  }
  event.preventDefault();

  if (anchor.href.includes(env.COMMUNITY_URL!)) {
    iframe.contentWindow?.open(anchor.href, '_self');
    return;
  }

  window.open(anchor.href, 'inGameCommunityTab');
};
