/*!
 * NativeBus for PopupWebView
 * Copyright(C) King, 2018
 */

(function()
{
    "use strict";

    var nativeBus = {
        Result: Object.freeze({
            FAILURE: 0,
            SUCCESS: 1
        })
    };

    // Configure window._messageBus used for low-level communication between
    // web page and PopupWebView native implementation.
    var messageBus = {};

    // Unify communication over all platforms.
    if (window._messageBus)
    {
        // Android PopupWebView.
        messageBus = window._messageBus;
    }
    else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers._messageBus)
    {
        // iOS PopupWebView.
        window._messageBus = messageBus;
        var iOSBus = window.webkit.messageHandlers._messageBus;

        messageBus.postMessage = function(msgName, msgPayload, responseCallbackId)
        {
            iOSBus.postMessage(JSON.stringify({
                name: msgName,
                payload: msgPayload,
                id: responseCallbackId
            }));
        };

        messageBus.sendMessageResponse = function(responseCallbackId, resultValue, respPayload)
        {
            iOSBus.postMessage(JSON.stringify({
                result: resultValue,
                payload: respPayload,
                id: responseCallbackId
            }));
        };
    }
    else if (typeof (window.external) !== "undefined" && "notify" in window.external)
    {
        // window.external.notify is undefined even though you can make calls to it
        // without any issues
        // MS Edge webcontrol
        window._messageBus = messageBus;

        messageBus.postMessage = function(msgName, msgPayload, responseCallbackId)
        {
            window.external.notify(JSON.stringify({
                name: msgName,
                payload: msgPayload,
                id: responseCallbackId
            }));
        };

        messageBus.sendMessageResponse = function(responseCallbackId, resultValue, respPayload)
        {
            window.external.notify(JSON.stringify({
                result: resultValue,
                payload: respPayload,
                id: responseCallbackId
            }));
        };
    }
    else
    {
        // Classic web browser.
        window._messageBus = messageBus;

        messageBus.postMessage = function(msgName, msgPayload, responseCallbackId)
        {
            window.console.log("message [" + msgName + "] sent with [" + msgPayload + "] payload");
            this.onMessageResponse(responseCallbackId, msgName, nativeBus.Result.SUCCESS, null);
        };

        messageBus.sendMessageResponse = function()
        {
            // Empty function.
        };
    }

    // Add messageBus handler for messages received from PopupWebView native implementation.
    var messageListener = null;

    messageBus.onMessageReceived = function(msgName, msgPayload, responseCallbackId)
    {
        var responder = null;
        if ((typeof (responseCallbackId) === "number") && isFinite(responseCallbackId) && (responseCallbackId >= 0))
        {
            var that = this;
            var responseSent = false;
            responder = {
                sendMessageResponse: function(resultValue, respPayload)
                {
                    if (!responseSent)
                    {
                        responseSent = true;

                        if (resultValue !== nativeBus.Result.SUCCESS)
                        {
                            resultValue = nativeBus.Result.FAILURE;
                        }

                        if (typeof (respPayload) === "object")
                        {
                            if (respPayload)
                            {
                                respPayload = JSON.stringify(respPayload);
                            }
                        }
                        else if (typeof (respPayload) !== "string")
                        {
                            respPayload = null;
                        }

                        try
                        {
                            that.sendMessageResponse(responseCallbackId, resultValue, respPayload);
                        }
                        catch (e)
                        {
                            window.console.error("cannot send message response to PopupWebView native implementation (" + e.message + ")");
                        }
                    }
                }
            };
        }

        if (messageListener)
        {
            try
            {
                if (typeof (messageListener) === "function")
                {
                    messageListener(msgName, msgPayload, responder);
                }
                else if (msgName && (typeof (msgName) === "string"))
                {
                    if (typeof (messageListener[msgName]) === "function")
                    {
                        messageListener[msgName](msgPayload, responder);
                    }
                    else if (responder)
                    {
                        responder.sendMessageResponse(nativeBus.Result.FAILURE, "unknown message");
                    }
                }
                else if (responder)
                {
                    responder.sendMessageResponse(nativeBus.Result.FAILURE, "invalid message name");
                }
            }
            catch (e)
            {
                var str = "unexpected error while calling message listener (" + e.message + ")";
                window.console.warn(str);

                if (responder)
                {
                    responder.sendMessageResponse(nativeBus.Result.FAILURE, str);
                }
            }
        }
        else if (responder)
        {
            responder.sendMessageResponse(nativeBus.Result.FAILURE, "unknown message");
        }
    };

    // Add messageBus handler for responses received from PopupWebView native implementation.
    var requestsCB = [];

    messageBus.onMessageResponse = function(responseCallbackId, msgName, resultValue, respPayload)
    {
        if ((typeof (responseCallbackId) === "number") && isFinite(responseCallbackId) &&
            (responseCallbackId >= 0) && (responseCallbackId < requestsCB.length))
        {
            var cb = requestsCB[responseCallbackId];
            requestsCB[responseCallbackId] = null;

            if (typeof (cb) === "function")
            {
                try
                {
                    cb(msgName, resultValue, respPayload);
                }
                catch (e)
                {
                    window.console.warn("unexpected error while calling message response callback (" + e.message + ")");
                }
            }
        }
    };

    // Add nativeBus API setMessageListener(l).
    // Listener l can be:
    // - a simple function called with following arguments l(msgName, msgPayload, responder) when a
    //   message with [msgName] name and [msgPayload] payload is received from PopupWebView native implementation;
    // - an object which keys correspond to listened messages names, then when a message with name [msgName] is
    //   received from PopupWebView native implementation, corresponding listener method is called as
    //   l[msgName](msgPayload, responder).
    // In both cases, [responder] is null (if caller has not specified any response callback), or a valid object with
    // only one method (if caller has specified a response callback). To send response information just call
    // responder.sendMessageResponse(result, respPayload) with [result] one of the values of nativeBus.Result, and
    // [respPayload] the response payload (may be null, a string or a valid object which is automatically encoded
    // in JSON string).
    nativeBus.setMessageListener = function(l)
    {
        if ((typeof (l) === "function") || (typeof (l) === "object"))
        {
            messageListener = l;
        }
        else
        {
            messageListener = null;
        }
    };

    // Add nativeBus API postMessage(msgName, msgPayload, responseCB).
    // [msgName] must be a valid non-empty string or no message will be posted.
    // [msgPayload] may be null, a string or a valid object which is automatically encoded in JSON string.
    // [responseCB] callback may be null or a valid function that will be called as
    // responseCB(msgName, result, respPayload) with [msgName] the message name, [result] one of nativeBus.Result
    // values and [respPayload] the response payload.
    nativeBus.postMessage = function(msgName, msgPayload, responseCB)
    {
        if (msgName && (typeof (msgName) === "string"))
        {
            if (typeof (msgPayload) === "object")
            {
                if (msgPayload)
                {
                    msgPayload = JSON.stringify(msgPayload);
                }
            }
            else if (typeof (msgPayload) !== "string")
            {
                msgPayload = null;
            }

            var id = -1;
            if (typeof (responseCB) === "function")
            {
                var size = requestsCB.length;
                for (id = 0; id < size; ++id)
                {
                    if (!requestsCB[id])
                    {
                        requestsCB[id] = responseCB;
                        break;
                    }
                }

                if (id >= size)
                {
                    requestsCB.push(responseCB);
                }
            }

            try
            {
                messageBus.postMessage(msgName, msgPayload, id);
            }
            catch (e)
            {
                var str = "cannot post message to PopupWebView native implementation (" + e.message + ")";
                window.console.error(str);

                if (id >= 0)
                {
                    requestsCB[id] = null;

                    try
                    {
                        responseCB(msgName, nativeBus.Result.FAILURE, str);
                    }
                    catch (ee)
                    {
                        window.console.warn("unexpected error while calling message response callback (" + ee.message + ")");
                    }
                }
            }
        }
        else if (typeof (responseCB) === "function")
        {
            try
            {
                responseCB(msgName, nativeBus.Result.FAILURE, "invalid null or empty message name");
            }
            catch (e)
            {
                window.console.warn("unexpected error while calling message response callback (" + e.message + ")");
            }
        }
    };

    if (typeof (exports) === "object")
    {
        // Export nativeBus interface for webpack.
        module.exports = nativeBus; // eslint-disable-line
    }
    else
    {
        // Export nativeBus interface as a global.
        window.nativeBus = nativeBus;
    }
})();
